export const tribeTermsAndConditions = `Payment Policy
To successfully complete your pre-booking, you will be required to make a deposit of $1000 TTD ($160 USD). Deadline for pre booking payment is July 1st, 2024,  
This deposit will be applied to your 2025 costume package down payment. You will be required to complete the full costume down payment on the selected costume package of your choice within five days after the registration start date in July/August. 
If payment is received within the five days after the costume registration start date you are guaranteed a spot in TRIBE Carnival band and the costume of your choice in the 2025 Trinidad & Tobago Carnival.
 
Payments for pre-bookings, can be paid: 

Online - Credit Card (Visa & MasterCard ONLY).
At the Mas Camp – Cash or Linx 

PLEASE NOTE THAT YOUR PRE-BOOKING WILL ONLY BE COMPLETED AND VALID WHEN THE BOOKING DEPOSIT PAYMENT IS RECEIVED. 

Disclaimer 

Please note that ALL credit card transactions are billed in USD$, the rate of conversion will be determined by your bank at the time of booking.
Important: For customers outside of the U.S., the amount reflected on your credit card statement is the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.  
Refunds/Returns Policy
Please note that once a pre-booking deposit is made, it is non-transferable. 

If Carnival 2025 is cancelled or postponed, your payment will be transferred to the next available Trinidad Carnival for booking. 

Costume Selection
Persons who have completed their pre-booking will be required to select their costume in the second phase when costume registration begins. 


Consumer Data Privacy Statement
Information Collection, Use, and Sharing  
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone. 

Your contact information will be used to communicate with you in regard to your booking and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization. 

Security 
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. 

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page. 
Customer Service Contact Information
Email:  info@carnivaltribe.com 
Phone: 235-6800

Experience the Magic Ltd. | 20 Rosalino Street, Woodbrook, Trinidad
 `;


export const blissTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31st December 2024. 
  
  Minimum Deposit: TT $2700/US $432 for all male and female costumes. All add-on items such as feathered pieces and backpacks will require an additional deposit. The total minimum deposit amount will be calculated on checkout.

Costumes packages must be paid in full prior to collection. Payments could be made via the following options:

1.At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2.Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE. 

DEPOSITS MUST BE MADE WITHIN FIVE (5) WORKING DAYS

COSTUMES MUST BE PAID FOR IN FULL BY 31ST DECEMBER 2024

Disclaimers

1.Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2.Costumes remain the property of the band until paid in FULL and collected.
3.If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4.Please note that ALL credit cards are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.


Refunds/Returns Policy

CANCELLATION AND REFUNDS:

If Trinidad Carnival is postponed due to unforeseen circumstances, you can retain your reservation and it will be honored for the rescheduled dates or the next Trinidad Carnival.
Exchange or switching of sections, changing of sizes and transfers will be facilitated up until August 15th, 2024. Requests must be submitted in writing to info@blisscarnival.com.
No returns are accepted after your costume is collected.
Cancellation requests for any other reason should be submitted via email to info@blisscarnival.com. If applicable, any refunds will be returned to the original form of payment used for the costume, i.e., if you paid by credit card, the refund will be issued to your credit card used at the time of reservation. Please note your bank may take up to seven (7) days to post the credit to your account.
Refunds are processed 7–10 working days from the day the refund request is received. Refunds are subject to cancellation penalties outlined below.
You agree that BLISS shall not be liable to make any refund to you for costume packages that are wholly or partially unused except as expressly stated herein or as otherwise required by law. Refunds shall be made as specified herein, or in the cancellation policy section of these Terms and Conditions.
 
CANCELLATION PENALTIES

All down payments are non-refundable. Once a down payment is received, your custom-made costume is ordered for production.
Cancellation requests received via email to info@blisscarnival.com on or before September 1st, 2024, will receive a refund of the amount paid towards the costume less the down payment.
Cancellation requests received after September 1st, 2024, will not be eligible for any refund.

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The costume owner must log into their account and insert the name of the person authorized to collect on the owner’s behalf. The costume owner should then share their unique QR code with the authorized collector. The authorized person must present a valid form of identification on collected.

All costumes must be paid in full to be eligible for costume delivery.

The costume distribution schedule will be emailed to all masqueraders and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: info@blisscarnival.com
Experience The Magic Ltd. | 20 Rosalino Street, Woodbrook, Trinidad
 `;

export const pureTermsAndConditions = `Payment Policy 

To successfully complete your pre-booking, you will be required to make a deposit of $1000 TTD ($160 USD).  

This deposit will be applied to your 2025 costume package down payment. You will be required to complete the full down payment on the selected costume package of your choice within five days after the registration start date in July/August.  

If payment is received within the five days after the registration start date you are guaranteed a spot in TRIBE Carnival band and the costume of your choice in the 2025 Trinidad & Tobago Carnival. 

  
Payments for pre-bookings, can be paid:  
 
Online - Credit Card (Visa & MasterCard ONLY). 

At the Mas Camp – Cash or Linx  
 
PLEASE NOTE THAT YOUR PRE-BOOKING WILL ONLY BE COMPLETED AND VALID WHEN THE DEPOSIT PAYMENT IS RECEIVED.   
 
Disclaimer  
 
Please note that ALL credit card transactions are billed in USD$, the rate of conversion will be determined by your bank at the time of booking. 

Important: For customers outside of the U.S., the amount reflected on your credit card statement is the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.  Shape 

Refunds/Returns Policy 

Please note that once a pre-booking deposit is made, it is non-transferable.  
 
If Carnival 2025 is cancelled or postponed, your payment will be transferred to the next available Trinidad Carnival for booking.  
Shape 

Costume Selection 

Persons who have completed their pre-booking will be required to select their costume in the second phase when costume registration begins.  
 
The pre-booking fee can be applied to all designs within the TRIBE Carnival band EXCEPT franchised/privately owned sections.Shape 

Consumer Data Privacy Statement 

Information Collection, Use, and Sharing   
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.  
 
Your contact information will be used to communicate with you in regard to your booking and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.  
 
Security  
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.  
 
Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page. Shape 

Customer Service Contact Information 

Email:  info@carnivaltribe.com  

Phone: 235-6800 

Shape 

Experience the Magic Ltd. | 20 Rosalino Street, Woodbrook, Trinidad `;

export const hartsTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31st December 2024. 
  
  Minimum Deposit: TT$2000 (male and female). All add-on items such as feathered pieces and backpacks will require an additional deposit. The total minimum deposit amount will be calculated on checkout.

Costume packages must be paid for in full prior to collection. Payments could be made via the following options:

1.At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2.Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

DEPOSITS MUST BE MADE WITHIN FIVE (5) WORKING DAYS

COSTUMES MUST BE PAID FOR IN FULL BY 31ST DECEMBER 2024


Disclaimers

1.Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2.Costumes remain the property of the band until paid for in FULL and collected.
3.If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4.Please note that ALL credit cards issued outside of Trinidad & Tobago are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.




Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Exchange or switching of sections, changing of sizes and transfers will be facilitated up until August 15th, 2024. Requests must be submitted in writing to harts@hartscarnival.com. No returns are accepted after your costume is collected.


Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The costume owner must log into their account and insert the name of the person authorized to collect on the owner’s behalf. The costume owner should then share their unique QR code with the authorized collector. The authorized person must present a valid form of identification on collected.

All costumes must be paid in full to be eligible for costume delivery.

The costume distribution schedule will be emailed to all masquerader and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: harts@hartscarnival.com
The Ultimate Hart Ltd. | 18 Rosalino Street, Woodbrook, Trinidad `;

export const theLostTribeTermsAndConditions = `Payment Policy
To successfully complete your pre-booking, you will be required to make a deposit of $1000 TTD ($160 USD). Deadline for pre booking payment is July 1st, 2024,  
This deposit will be applied to your 2025 costume package down payment. You will be required to complete the full costume down payment on the selected costume package of your choice within five days after the registration start date in July/August. 
If payment is received within the five days after the costume registration start date you are guaranteed a spot in The Lost Tribe Carnival band and the costume of your choice in the 2025 Trinidad & Tobago Carnival.
 
Payments for pre-bookings, can be paid: 

Online - Credit Card (Visa & MasterCard ONLY).
At the Mas Camp – Cash or Linx 

PLEASE NOTE THAT YOUR PRE-BOOKING WILL ONLY BE COMPLETED AND VALID WHEN THE BOOKING DEPOSIT PAYMENT IS RECEIVED. 

Disclaimer 

Please note that credit card transactions may be billed in USD$, the rate of conversion will be determined by your bank at the time of booking.
Important: For customers outside of the U.S., the amount reflected on your credit card statement is the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.  
Refunds/Returns Policy
Please note that once a pre-booking deposit is made, it is non-transferable. 

If Carnival 2025 is cancelled or postponed, your payment will be transferred to the next available Trinidad Carnival for booking. 

Costume Selection
Persons who have completed their pre-booking will be required to select their costume in the second phase when costume registration begins. 

Consumer Data Privacy Statement
Information Collection, Use, and Sharing  
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone. 

Your contact information will be used to communicate with you in regard to your booking and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization. 

Security 
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. 

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page. 
Customer Service Contact Information
Email:  info@losttribecarnival.com 
Phone: 235-6800

Prelim Services Ltd. | 59 Alberto Street, Woodbrook, Trinidad
 `;

export const rogueTermsAndConditions = `Payment Policy 

To successfully complete your pre-booking, you will be required to make a deposit of $1000 TTD ($160 USD).  

This deposit will be applied to your 2025 costume package down payment. You will be required to complete the full down payment on the selected costume package of your choice within five days after the registration start date in July/August.  

If payment is received within the five days after the registration start date you are guaranteed a spot in TRIBE Carnival band and the costume of your choice in the 2025 Trinidad & Tobago Carnival. 

  
Payments for pre-bookings, can be paid:  
 
Online - Credit Card (Visa & MasterCard ONLY). 

At the Mas Camp – Cash or Linx  
 
PLEASE NOTE THAT YOUR PRE-BOOKING WILL ONLY BE COMPLETED AND VALID WHEN THE DEPOSIT PAYMENT IS RECEIVED.   
 
Disclaimer  
 
Please note that ALL credit card transactions are billed in USD$, the rate of conversion will be determined by your bank at the time of booking. 

Important: For customers outside of the U.S., the amount reflected on your credit card statement is the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.  Shape 

Refunds/Returns Policy 

Please note that once a pre-booking deposit is made, it is non-transferable.  
 
If Carnival 2025 is cancelled or postponed, your payment will be transferred to the next available Trinidad Carnival for booking.  
Shape 

Costume Selection 

Persons who have completed their pre-booking will be required to select their costume in the second phase when costume registration begins.  
 
The pre-booking fee can be applied to all designs within the TRIBE Carnival band EXCEPT franchised/privately owned sections.Shape 

Consumer Data Privacy Statement 

Information Collection, Use, and Sharing   
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.  
 
Your contact information will be used to communicate with you in regard to your booking and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.  
 
Security  
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.  
 
Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page. Shape 

Customer Service Contact Information 

Email:  info@carnivaltribe.com  

Phone: 235-6800 

Shape 

Experience the Magic Ltd. | 20 Rosalino Street, Woodbrook, Trinidad `;
