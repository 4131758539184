import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { BlackListUserPageService } from "../../../../../services";

const MODULE_NAME = "profile.machines.states.suspendUser";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.BlackListUserPageService") as BlackListUserPageService;
};

export const suspendUser = async (context: any, event: any) => {
  const currentState = "SUSPEND_USER";
  const method = "suspendUser";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      const content = await srv.blacklistUser(event?.payload);
      if (content?.email) {
        return resolve({
          key: "SUSPEND_USER_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "User suspend failed";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "SUSPEND_USER_FAILED",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "SUSPEND_USER_FAILED",
        value: err?.message,
      });
    }
  });
};
