import {
  IAgentPlugin,
  IPluginMethodMap,
} from "@vlinder-web/plugin-agent-service-react";
import config from "../config/analyticsWidget.config.json";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { VApplication } from "../../../application";
import { GET_MASQUERADER_DETAILS, GET_PRESENTATIONS } from "../graphql";
import { pretty } from "@vlinder-web/utils-react";
const lookup = require("country-code-lookup");

const getGraphQLModule = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export interface IAnalyticsWidgetMethods extends IPluginMethodMap {
  getAnalyticsWidgetProps(args: any): Promise<any>;
}

export class AnalyticeWidgetService implements IAgentPlugin {
  readonly methods: IAnalyticsWidgetMethods;

  constructor() {
    this.methods = {
      getAnalyticsWidgetProps: this.getProps.bind(this),
    };
  }

  async getProps(args: any) {
    const graphqlModule = getGraphQLModule();
    const client = graphqlModule.getClient("graphql-gateway");
    const filter = {
      // year:{
      //   eq:"2024"
      // },
      // status: {
      //   eq: "active",
      // },
    };
    try {
      if (client) {
        const _res = await client.query({
          query: GET_PRESENTATIONS,
          variables: {
            filter: filter,
            options: {
              limit: args?.size ?? 10,
              skip: args?.offset ?? 0,
              sort: {
                order: "asc",
                field: "status",
              },
            },
          },
          fetchPolicy: "network-only",
        });
        const defaultPresentation =
          _res?.data?.getPresentations?.content[0]?.id;
        const _filter = {
          Box___presentation: {
            eq: args?.criteria?.id ?? defaultPresentation,
          },
        };
        const res = await client.query({
          query: GET_MASQUERADER_DETAILS,
          variables: {
            filter: _filter,
          },
          fetchPolicy: "network-only",
        });
        if (res?.data) {
          const _map = this.mapFormat(res?.data?.getCountryHeatMap?.data);
          const _card = this.cardFormat(res?.data?.getCountryHeatMap?.data);
          const formattedData = this.getTransformConfig(config, _map, _card);
          let biggestCountObject = _map.reduce((maxCountObj, currentObj) => {
            if (currentObj.count > maxCountObj.count) {
              return currentObj;
            }
            return maxCountObj;
          }, _map[0]);

          return {
            map: config,
            highest:
              biggestCountObject?.count !== undefined
                ? biggestCountObject?.count
                : 0,
          };
        }
      }
    } catch (err: any) {
      let _display_message =
        err?.graphQLErrors &&
        Array.isArray(err?.graphQLErrors) &&
        err?.graphQLErrors.find((item: any) => item.extensions);
      if (_display_message?.extensions?.display_message) {
        throw Error(_display_message?.extensions?.display_message);
      }

      throw err;
    }
  }

  private getTransformConfig(data: any, map?: any, card?: any) {
    data.layout[0].colProps[8].compProps.data = map;
    console.log("card==>", pretty(card.length));
    let count = 0;
    if (card.length != 0) {
      card.forEach((item: any) => {
        data.layout[0].colProps[count++].compProps = item;
      });
      for (let i = card.length; i < 8; i++) {
        data.layout[0].colProps[count++].compProps = {};
      }
    } else {
      for (let i = 0; i < 8; i++) {
        data.layout[0].colProps[count++].compProps = {};
      }
    }
    const jsonString = JSON.stringify(data);
    return JSON.parse(jsonString);
  }

  private mapFormat(data: any) {
    const _checkValueIs = (country: string) => {
      return lookup.byCountry(country)?.internet;
    };
    let _data: any[] = [];
    data.forEach((item: any) => {
      let country = _checkValueIs(item?.Name);
      if (country !== undefined) {
        _data.push(item?.ISO3);
      }
    });
    const cty = Array.from(new Set(_data));
    let arr: any[] = [];
    cty.forEach((item: any) => {
      let count = 0;
      let Name = "";
      data.forEach((obj: any) => {
        if (item === obj.ISO3) {
          Name = obj.Name;
          count += Number(obj.count);
        }
      });
      arr.push({
        ISO3: item,
        Name: Name,
        count: count,
      });
    });
    return arr;
  }

  private cardFormat(data: any) {
    const _checkValueIs = (country: string) => {
      return lookup.byCountry(country)?.internet;
    };
    let _data: any[] = [];
    data.forEach((item: any) => {
      let country = _checkValueIs(item?.Name);
      if (country !== undefined) {
        _data.push(item?.ISO3);
      }
    });
    const cty = Array.from(new Set(_data));
    let arr: any[] = [];
    cty.forEach((item: any) => {
      let count = 0;
      let Name = "";
      data.forEach((obj: any) => {
        if (item === obj.ISO3) {
          Name = obj.Name;
          count += Number(obj.count);
        }
      });
      arr.push({
        ISO3: item,
        Name: Name,
        count: count,
      });
    });
    if (arr.length > 1) {
      arr.sort((a, b) => {
        if (a.count !== b.count) {
          return b.count - a.count;
        }
        return a.Name.localeCompare(b.Name);
      });
    }

    if (arr.length > 8) arr = arr.slice(0, 8);
    let card: any[] = [];
    let x = 0;
    arr.forEach((item: any) => {
      card.push({
        appCardId: x++,
        appCardISO3: item?.ISO3,
        appCardTitle: item?.Name,
        appCardList: [
          {
            name: "Count",
            value: item?.count,
          },
        ],
      });
    });
    return card;
  }
}
