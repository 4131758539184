import React, { useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import styled from "styled-components";

interface ICoupon {}

interface ICouponProps {
    type: string;
    id: string;
    label: string;
    value: string;
    required: boolean;
    buttonText: string;
    placeholder: string;
    errorText: string;
    onCompChange?(id: string, payload: any): void;
    hasError?: boolean;
    disabled?: boolean;
}

const FormLabel = styled(Form.Label)`
    color: ${(props) => props.color || "black"};
`;

const FeedbackOtherLabel = styled(Form.Control.Feedback)`
    color: red;
`;

export const Coupon = ({
    id,
    label,
    value,
    required,
    buttonText,
    placeholder,
    errorText,
    onCompChange,
    hasError,
    disabled,
}: ICouponProps) => {
    const [couponValue, setCouponValue] = useState(value ?? "");
    const [isInvalid, setInvalid] = useState(false);

    useEffect(() => {
        if (hasError) {
            setInvalid(true);
        } else {
            setInvalid(false);
        }
    }, [hasError]);

    const onChange = (id: string, e: any) => {
        setCouponValue(e?.target?.value);
        onCompChange && onCompChange(id, e?.target?.value);
    };

    const _renderLabelIf = () => {
        if (label) {
            return (
                <FormLabel
                    style={{
                        fontSize: "14px",
                        marginLeft: "12px",
                        marginTop: "2%",
                    }}
                >
                    {label}
                    {required && <sup style={{ color: "red" }}>*</sup>}
                </FormLabel>
            );
        }
        return null;
    };

    const _renderErrorTextIf = () => {
        if (hasError && errorText) {
            return (
                <FeedbackOtherLabel type="tooltip">
                    {errorText}
                </FeedbackOtherLabel>
            );
        }
        return null;
    };

    return (
        <>
            <Form.Group id={id} className="mb-4 formgroup">
                {_renderLabelIf()}
                <InputGroup>
                    <Form.Control
                        className="formcontrol shadow-none"
                        value={couponValue}
                        onChange={onChange.bind(null, id)}
                        required={required}
                        type="text"
                        placeholder={placeholder}
                        disabled={disabled}
                        isInvalid={isInvalid}
                    />
                    <Button variant="primary" disabled={disabled}>
                        {buttonText}
                    </Button>
                </InputGroup>
                {_renderErrorTextIf()}
            </Form.Group>
        </>
    );
};

Coupon.defaultProps = {
    required: false,
    disabled: false,
    hasError: false,
};
