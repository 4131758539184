import React, { useEffect } from "react";
import { LoggerModule } from "@vlinder-web/logger-module-react";

import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import { actions } from "./actions";
import { MACHINE_CONFIG } from "./config";
import { services } from "./services";

export const suspendUserContext = React.createContext({});
export let suspendUserMachine: any;

const MODULE_NAME = "machines.pageContent";
const log: any = LoggerModule?.getInstance();

export const useSMSuspendUser = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  suspendUserMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(suspendUserMachine);

  return {
    suspendUserMachine,
    suspendUserState: state,
    suspendUserSend: send,
  };
};
