import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Creatable from "react-select/creatable";
import * as S from "./styles";
export interface ICreatableSelectInputProps {
    id: string;
    label?: string;
    labelColor?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    options?: any[];
    textVal?: string;
    textLabel?: string;
    required?: boolean;
    placeholder?: string;
    onCompChange?(id: string, payload: any): void;
    hasError?: boolean;
}

export const CreatableSelectInput = ({
    id,
    label,
    labelColor,
    options = [],
    errorText,
    textLabel,
    textVal,
    required,
    placeholder,
    onCompChange,
    hasError,
    readOnly,
    disabled,
}: ICreatableSelectInputProps) => {
    const [textValue, setTextValue] = useState({ label: textLabel, value: textVal } ?? "");
    const [OptionList, setOptionList] = useState<any[]>(options);

    useEffect(() => {
        // Update options list if initial value is not in the options
        if (textVal && textLabel && !options.some(option => option.value === textVal)) {
            setOptionList(prevOptions => [...prevOptions, { label: textLabel, value: textVal }]);
        }
    }, [textVal, textLabel, options]);

    const onChange = (opt?: any) => {
        setTextValue({ label: opt?.label, value: opt?.value });

        // Add new option to options list if it doesn't exist
        if (opt?.value && !OptionList.some(option => option.value === opt.value)) {
            setOptionList(prevOptions => [...prevOptions, { label: opt.label, value: opt.value }]);
        }

        const index = OptionList.findIndex(option => option.label === opt.label && option.value === opt.value);
        const payload = {
            value: opt?.value,
            valid: opt?.value != "",
            index: index,
            isExist: OptionList.some(option => option.value === opt?.value),
        };

        if (onCompChange) {
            onCompChange(id, payload);
        }
    };

    const _renderLabelIf = () => {
        if (label) {
            return (
                <Form.Label style={{ color: labelColor }}>
                    {label} {required && <sup style={{ color: "red" }}>*</sup>}
                </Form.Label>
            );
        }
        return null;
    };

    const _renderErrorTextIf = () => {
        if (hasError && errorText) {
            return (
                <S.FeedbackOtherLabel type="tooltip">
                    {errorText}
                </S.FeedbackOtherLabel>
            );
        }
        return null;
    };

    return (
        <Form.Group id={id} className="mb-4 formgroup" style={{ padding: "10px 0 0 10px" }}>
            {_renderLabelIf()}
            <InputGroup>
                <S.Container
                    isDisabled={disabled}
                    // isReadOnly={readOnly}
                    options={OptionList}
                    value={textValue}
                    formatCreateLabel={(inputText) => `${inputText}`}
                    onChange={onChange}
                    placeholder={placeholder}
                    styles={S.Dropdown}
                />
            </InputGroup>
            {_renderErrorTextIf()}
        </Form.Group>
    );
};

CreatableSelectInput.defaultProps = {
    required: false,
    hasError: false,
    readOnly: false,
    disabled: false,
};
