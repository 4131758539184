import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { UsersListPageService } from "../../../../../services";

const MODULE_NAME = "profile.machines.states.deleteUser";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.UsersListPageService") as UsersListPageService;
};

export const deleteUser = async (context: any, event: any) => {
  const currentState = "DELETE_USER";
  const method = "revokeCred";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      const content = await srv.deleteUser(event?.payload);
      if (content) {
        return resolve({
          key: "DELETE_USER_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "User deletion failed";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "DELETE_USER_FAILED",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "DELETE_USER_FAILED",
        value: err?.message,
      });
    }
  });
};
